import React from "react"
import ContentLoader from "react-content-loader"

const MyLoaderCategory = (props) => (
  <ContentLoader 
    speed={2}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="61" y="9" rx="3" ry="3" width="270" height="12" /> 
    <circle cx="54" cy="90" r="40" /> 
    <circle cx="146" cy="90" r="40" /> 
    <circle cx="239" cy="90" r="40" /> 
    <circle cx="334" cy="90" r="40" />
  </ContentLoader>
)

export default MyLoaderCategory

