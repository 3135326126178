import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useParams,NavLink } from 'react-router-dom';
import MyComponent from './Lista';
import Lista from './Lista2';
import Product from './ProdusDetail';
import Homepage from './Homepage';
const Splitlink = params => {
  
 // let { pagina } = useParams();

  let pagina=params.route.pagina;
  let url=params.location.pathname;
 console.log(pagina);
 console.log(url);
  

  return ( 
    <React.Fragment>
      
      
       
          {pagina=='index' && (
              <Homepage />
          )}
          {pagina=='categorie' && (
              <MyComponent />
          )}
          {pagina=="product" && (
              <Product url={url} />
          )}
          

          
         
        

       
    
     {/* {!isLoading && loadedUsers && <UsersList items={loadedUsers} />} */}
    </React.Fragment>
  );
};

export default Splitlink;

