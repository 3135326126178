import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    
    height={560}
    viewBox="0 0 367 560"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="6" y="-20" rx="0" ry="0" width="364" height="498" /> 
    <circle cx="15" cy="494" r="9" /> 
    <circle cx="37" cy="494" r="9" /> 
    <circle cx="60" cy="494" r="9" /> 
    <circle cx="82" cy="494" r="9" /> 
    <circle cx="105" cy="494" r="9" /> 
    <rect x="5" y="515" rx="3" ry="3" width="365" height="8" /> 
    <rect x="232" y="523" rx="0" ry="0" width="1" height="0" /> 
    <rect x="6" y="534" rx="3" ry="3" width="150" height="10" /> 
    <rect x="215" y="534" rx="3" ry="3" width="150" height="10" />
  </ContentLoader>
)

export default MyLoader
