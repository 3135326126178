import './App.css';
import MyComponent from './Lista';
import { Jumbotron, Button } from 'react-bootstrap';
import { CachedFetchProvider } from "react-cached-fetch";

function App() {
  return (
    <CachedFetchProvider>
    <div className="App">
      <MyComponent />
    </div> 
    </CachedFetchProvider>
  );
}

export default App;
