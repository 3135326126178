import React from "react";
const CheckBox = ({key,label,name,value,filter, checked}) => {
		
        const handleItemClick = (event, brand) =>{
            //console.log(brand);
            if (event.target.checked) {
              //  console.log('✅ Checkbox is checked');
                filter('checked', brand);
              } else {
              //  console.log('⛔️ Checkbox is NOT checked');
                filter('unchecked', brand);
              }
              
            
        }
		return (
			<label className={`lb ${checked=="selected" ? "checked" : ""} `}>
				<input
         defaultChecked={checked=="selected" ? checked : ''}
					type="checkbox"
					className="cm-product-filters-checkbox form-checkbox w-5 h-5 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-heading checked:hover:bg-heading checked:focus:bg-heading"
					value={value}
                    onChange={(e) =>handleItemClick(e,value)}
				/>
				<span>{label}</span>
			</label>
		);
}

export default CheckBox;