import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Link} from 'react-router';
import $ from 'jquery'; 
import MarimiFilter from "./marimi-filter";
import BrandFilter from "./brand-filter";
import CuloriFilter from "./color-filter";
import TipTocFilter from './tiptoc-filter';
import TocFilter from './toc-filter';
import FilteredItem from "./filtered-item";
//import { ColorFilter } from "./color-filter";
//import { PriceFilter } from "./price-filter";

const ShopFilters = ({filter, filtre}) => {
   // const [brands, setBrands] = useState([]);
   // const [categories, setCategories] = useState([]);
	//const { search } = useLocation();
    let search='';
    const doFilter = (feature_hash) =>{
       
      //  console.log(category);
        filter(feature_hash);
		$('.cm-product-filters').removeClass('sactive');
    }

	const doOpenFilters = (tip) =>{
		$('.cm-product-filters').addClass('sactive');
		if(window.innerWidth<768){
			$('.cm-product-filters .'+tip).addClass(tip+'-active');
		}
	}
	
	const doHideFilters = (tip) =>{
		$('.cm-product-filters').removeClass('sactive');
		if(window.innerWidth<768){
			$('.cm-product-filters .'+tip).removeClass(tip+'-active');
		}
	}
	return (
		<div className="cm-product-filters">
			{filtre && (
				<>
					{filtre[1] && (
					<div class="btn1 marimi" onClick={() => doOpenFilters('smarimi')} >
						{filtre[1]['filter']}
					</div>
					)}
					{filtre[2] && (
					<div class="btn1 culori" onClick={() => doOpenFilters('sculori')}>
						{filtre[2]['filter']}
					</div>
					)}
					{filtre[4] && (
					<div class="btn1 material" onClick={() => doOpenFilters('smaterial')}>
						{filtre[4]['filter']}
					</div>
					)}
					{filtre[6] && (
					<div class="btn1 stoc" onClick={() => doOpenFilters('sstoc')}>
						{filtre[6]['filter']}
					</div>
					)}
					{filtre[7] && (
					<div class="btn1 stoc2" onClick={() => doOpenFilters('sstoc2')}>
						{filtre[7]['filter']}
					</div>
					)}
					{filtre[3] && (
					<div class="btn1 pret" onClick={() => doOpenFilters('pret')}>
						{filtre[3]['filter']}
					</div>
					)}
					<div class="menu-mask2" onClick={() => doHideFilters('marimi')}></div>
					<div class="plp-sheets1">
					{filtre && (
					<MarimiFilter items={filtre[1]}  filter={doFilter}/>
					)}
					{filtre && (
					<CuloriFilter items={filtre[2]} filter={doFilter}/>
					)}
					{filtre && (
					<BrandFilter items={filtre[4]} filter={doFilter}/>
					)}
					{filtre && (
					<TipTocFilter items={filtre[6]} filter={doFilter}/>
					)}
					{filtre && (
					<TocFilter items={filtre[7]} filter={doFilter}/>
					)}
					</div>
			</>
			)}
		</div>
	);
}

export default ShopFilters;
