import React, { Component, useEffect, useState } from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {useHistory, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Scrollbar } from 'swiper/modules';
import Parser from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import Product from '../Produs';
import $ from 'jquery';
import axios from 'axios'; 
import { NumericFormat } from 'react-number-format';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';



const SliderProduse = props => {
  const [name, setName]=useState([]);
  const [items, setItems]=useState([]);
  const [isLoaded, setIsLoaded]=useState(true);
  const [crurl, setCrurl]=useState();
  const [iName, setIName]=useState(props.name);
  const [title, setTitle]=useState(props.title);
  const [moneda, setMoneda]=useState(window.moneda);
  const [lang_code, setLangCode]=useState(window.lang_code);
  const [sel_prod, setSelProd]=useState(0);
  const [prod_det, setProdDet]=useState([]);
  const [isBoxVisible, setIsBoxVisible]=useState(false);
  const [loadp, setLoadP]=useState(false);
  const [slides, setSlides]=useState([]);
  const [loc1, setLoc1]=useState(window.location.href);
  const [locrec, setLocRec1]=useState();

  
  
  //let slides=[];

  const openTooltip = async (event, product_id, url,image) => {
    if(window.innerWidth<768){
      event.preventDefault();
      //console.log(product_id);
      
        /*
        $.getJSON("index.php?dispatch=products.quick_view&product_id="+product_id+"", { is_ajax: 1}, function(data){
                    $('#products_'+iName+' .mht').html(data.text);
                });  
                */
                $('#products_'+iName+' .mht').html();
                setIsBoxVisible(true);
                
                //setLoadP(false);
              
               
              await fetch('index.php?dispatch=react.product&product_id='+product_id+'&comp_id='+window.comp_id) 
                  .then(res => res.json())
                  .then(
                    (result) => {
                      //console.log('zzz');
                      //console.log(result);
                      //console.log('zzz');
                      setSelProd(product_id);
                      setProdDet(result);
                     
                     
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      //console.log(error)
                    }
                  )
                
       // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
       if(props.name!='similare'){
        window.history.pushState(product_id, 'Title', url);
        }
      
         event.preventDefault();
    }else{
      
      //browserHistory.push(url);
            document.getElementsByClassName('breadcrumbs-grid')[0].style.display = 'none';
          
            //console.log(product_id);
            
              /*
              $.getJSON("index.php?dispatch=products.quick_view&product_id="+product_id+"", { is_ajax: 1}, function(data){
                          $('#products_'+iName+' .mht').html(data.text);
                      });  
                     
                      $('#products_'+iName+' .mht').html();
                    this.setState({
                      isBoxVisible: false,
                      loadp: false
                    });
                    
                    
                    await fetch('products.php?product_id='+product_id) 
                        .then(res => res.json())
                        .then(
                          (result) => {
                            
                            //console.log(result);
                            this.setState({
                              sel_prod: product_id,
                              prod_det: result,
                              imfirst: image,
                              imfirst: image,
                              loadedtp:'product',
                              loadp: true
                            });
                            
                          
                          },
                          // Note: it's important to handle errors here
                          // instead of a catch() block so that we don't swallow
                          // exceptions from actual bugs in components.
                          (error) => {
                            //console.log(error)
                          }
                        ) */
                      
            // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
            window.history.pushState(product_id, 'Title', url);
            
        
          }
          //console.log(window.innerWidth);

      }

      const closeTooltip = (event, product_id) => {
        //console.log(product_id);
     
        setIsBoxVisible(false);
        setLoadP(false);
        //setProdDet([]);
        setSelProd(0);
        
        
        window.history.pushState(product_id, 'Title', crurl);
        //console.log(window.location.href);
        //console.log(crurl);
       // $('#products_'+iName+' .mht').html('');
        event.preventDefault();

      }
       
      window.addEventListener('popstate', (event) => {
        event.preventDefault();
        let loc=window.location.href;
        let locrec=loc.split('/p/');
            //console.log(event.state);
            //console.log('backda');
            setIsBoxVisible(false);
            setLoadP(false);
            setProdDet([]);
            setSelProd(0);
            
            $('#products_'+iName+' .mht').html('');
        
        
       }, false);
       /*
      window.history.pushState({name: "browserBack"}, "on browser back click",loc1);
      window.history.pushState({name: "browserBack"}, "on browser back click", loc1);
       */


  useEffect(() => {
    //console.log(props);
   

    if(window.moneda!=''){
      setMoneda(window.moneda);
    }else if(lang_code=='bg'){
      setMoneda('лв');
      
    }else if(lang_code=='hu'){
      setMoneda('Ft');
      
    }else if(lang_code=='el'){
      setMoneda('€');
      
    }else if(lang_code=='it'){
      setMoneda('€');
      
    }else if(lang_code=='sl'){
      setMoneda('€');
      
    }else if(lang_code=='sk'){
      setMoneda('€');
      
    }else if(lang_code=='fr'){
      setMoneda('€');
      
    }else if(lang_code=='hr'){
      setMoneda('€');
      
    }else if(lang_code=='pl'){
      setMoneda('zl');
      
    }else if(lang_code=='cs'){
      setMoneda('Kč');
      
    }else{
      setMoneda('lei');
      
    }
    setName(props.name);
    let slides2=[];
    setItems(props.items);
    setTitle(props.title);
    setSlides(slides2);
    let loc=window.location.href;
    let locrec=loc.split('/p/');

    setLocRec1(locrec);
   
     setCrurl(locrec[0]);

    let produse=props.items;
    if(produse){
   
      ////console.log(produse);
      produse.forEach((item) => {  ////console.log(item);
          slides2.push(
            <SwiperSlide key={item.product_id} className={`ty-column5  ${item.produsul_zilei} `}>
              <div >
           <LazyLoad key={item.product_id} offset={300}>

           <form action="" method="post" name="product_form_97827" enctype="multipart/form-data" className="cm-disable-empty-files cm-ajax cm-ajax-full-render cm-ajax-status-middle cm-processed-form">
             <input type="hidden" name="result_ids" value="cart_status*,wish_list*,checkout*,account_info*"/>
             <input type="hidden" name="redirect_url" value={`index.php?dispatch=categories.view&category_id=${window.category_id}`}/>
             <input type="hidden" name={`product_data[${item.product_id}][product_id]`} value={item.product_id}/>
             <input type="hidden" name="security_hash" className="cm-no-hide-input" value="02f3d5b93a94313977afb80c54b4e9b5"/>
             <a className="ty-btn ty-btn__text cm-ajax ty-add-to-wish cm-submit text-button1" id={`button_wishlist_${item.product_id}`} href={`/index.php?dispatch=wishlist.add&product_id=${item.product_id}&is_ajax=1`}></a>
           </form>         
           <a href={item.url} onClick={e => openTooltip(e,item.product_id,item.url,item.image_link)}>
           
             <div className={`ty-grid-list__item ty-quick-view-button__wrapper a${item.colectie} ty-grid-list__item--overlay`}>
                   <div className="ty-grid-list__image" data-id={item.colectie}>
                   <LazyLoadImage  src={`${item.image_link}`} delayTime={5} alt={item.product} threshold={300} visibleByDefault={true}/>
                    <div className={`ty-product-labels ty-product-labels--top-right   cm-reload-${item.product_id}`} id={`product_labels_update_${item.product_id}`}>
                    {item.discount > 0 && (
                         <div className="ty-product-labels__item   ty-product-labels__item--discount">
                           <div className="ty-product-labels__content">{Math.round(((item.price*100/item.list_price)-100).toString().replace("-", ""))}</div>
                         </div>
                      )}  
                     </div>
                  
                   </div>
                  
                   {Parser(item.promotii.toString())}
                   {Parser(item.culori)}
                   <div className="ty-grid-list__item-name"><bdi>
                   {item.tip_produs!==null && item.tip_produs!=='' && (
                       <span className={`top  ${item.tip_produs} `}>{item.tip_produs}</span>
                   )}
                   {item.brand!==null && (
                       <span>{item.brand}</span>
                   )}
                    {item.product}
                     </bdi>
                   </div>
                   <div className="grid-list__rating">    
                   </div>
                   <div className="ty-grid-list__price ">
                   {item.list_price > 0 && (
                     <span className={`cm-reload-${item.product_id}`} id={`old_price_update_${item.product_id}`}>
                         <span className="ty-list-price ty-nowrap" id={`line_list_price_${item.product_id}`}><span className="ty-strike"><bdi><span id="sec_list_price_{item.product_id}" className="ty-list-price ty-nowrap">{item.list_price.split('.')[0]}<sup>{item.list_price.split('.')[1]}</sup></span>&nbsp;<span className="ty-list-price ty-nowrap">{moneda}</span></bdi></span></span>
                       
                     </span>
                      )} 
                                           <span className={`cm-reload-${item.product_id} ty-price-update`} id={`price_update_${item.product_id}`}>
                          <input type="hidden" name="appearance[show_price_values]" value="1"/>
                          <input type="hidden" name="appearance[show_price]" value="1"/>
                          {item.list_price > 0 && (
                          <span className="ty-price red" id={`line_discounted_price_${item.product_id}`}><bdi><span id={`sec_discounted_price_${item.product_id}`} className="ty-price-num"><NumericFormat
                                    value={item?.price?.split(".")[0]}
                                    thousandSeparator=" "
                                    displayType="text"
                                  /><sup>{item.price.split('.')[1]}</sup></span>&nbsp;<span className="ty-price-num">{moneda}</span></bdi></span>
                          )} 
                          {item.list_price < 1 && (
                          <span className="ty-price" id={`line_discounted_price_${item.product_id}`}><bdi><span id={`sec_discounted_price_${item.product_id}`} className="ty-price-num"><NumericFormat
                                    value={item?.price?.split(".")[0]}
                                    thousandSeparator=" "
                                    displayType="text"
                                  /><sup>{item.price.split('.')[1]}</sup></span>&nbsp;<span className="ty-price-num">{moneda}</span></bdi></span>
                          )} 
                      </span>
                   </div>
                   <div className="marimi">{item.caracteristici}</div>
                  
                   
             </div>
           </a>
         
           </LazyLoad>

           
         </div>
            </SwiperSlide>
          )
          });
      }
   
     
  }, [props]);
 
 
  ////console.log(imagini);
  return (
    <>
    {isLoaded===false ?
      <div>
        loading
      </div>
      :
      <>
      <div className='homepage-hotdeals '>
      <h2 class="ty-mainbox-simple-title">{title}</h2>
     <Swiper
        scrollbar={true}
        modules={[Pagination]}
        className="mySwiper"
        slidesPerView={1.3}
        spaceBetween={15}
        breakpoints={{
          768: {
            slidesPerView: "5",
          },
        }}
      
        rebuildOnUpdate="true"
        modules={[Scrollbar]}
      >
       {slides}
        
      </Swiper>
      <div id={`products_${iName}`} className={`popup view ${isBoxVisible ? "true" : "false"}`}>
          <span className="cart-close1" role="button" aria-label="Close" onClick={e => closeTooltip(e)}><span aria-hidden="true"></span></span>
          <div className="mht">
          <Product product_id={sel_prod} product={prod_det} moneda={moneda}/> 
          </div>
        </div>

        </div>
    </> 
            }
          </> 
  );
};

export default SliderProduse;