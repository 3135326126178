import React from "react"
import ContentLoader from "react-content-loader"

const MyLoaderMobile = (props) => (
  <ContentLoader 
  speed={2}
 
  height={360}
  viewBox="0 0 190 360"
  backgroundColor="#f3f3f3"
  foregroundColor="#ecebeb"
  {...props}
>
  <rect x="1" y="1" rx="0" ry="0" width="190" height="260" /> 
  <circle cx="9" cy="277" r="7" /> 
  <circle cx="31" cy="277" r="7" /> 
  <circle cx="54" cy="277" r="7" /> 
  <circle cx="76" cy="277" r="7" /> 
  <circle cx="99" cy="277" r="7" /> 
  <rect x="1" y="290" rx="3" ry="3" width="190" height="8" /> 
  <rect x="232" y="523" rx="0" ry="0" width="1" height="0" /> 
  <rect x="2" y="310" rx="3" ry="3" width="75" height="10" /> 
  <rect x="115" y="310" rx="3" ry="3" width="75" height="10" />
</ContentLoader>
)

export default MyLoaderMobile
