import React from "react"
import ContentLoader from "react-content-loader"

const ResponsiveProductMobile = (props) => (

  
  <ContentLoader 
  speed={2}
  height={800}
  backgroundColor="#f3f3f3"
  foregroundColor="#ecebeb"
  {...props}
> 

  <rect x="1" y="1" rx="0" ry="0" width={props.width1} height="500" /> 
  <rect x="232" y="523" rx="0" ry="0" width="1" height="0" /> 
  <rect x="3" y="515" rx="0" ry="0" width="2" height="1" /> 
  <rect x="15" y="556" rx="0" ry="0" width="100" height="16" /> 
  <rect x="130" y="556" rx="0" ry="0" width="80" height="16" /> 
  <circle cx="45" cy="622" r="30" /> 
  <circle cx="115" cy="622" r="30" /> 
  <circle cx="188" cy="622" r="30" /> 
  <circle cx="260" cy="622" r="30" /> 
  <rect x="15" y="523" rx="0" ry="0" width="300" height="16" />
</ContentLoader>
)

export default ResponsiveProductMobile