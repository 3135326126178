import React, { Component, useEffect, useState } from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import {useHistory, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Parser from 'html-react-parser';
import $ from 'jquery';
import axios from 'axios'; 
import Notification from './Notification';
import SliderProduse from './components/sliderProduse';

import 'swiper/css';
import 'swiper/css/pagination';

import './Homepage.css';


const Homepage = props => {
  const [produs, setProdus]=useState([]);
  const [isLoaded, setIsLoaded]=useState(false);
  const [mainBanner, setMainBanner]=useState([]);
  const [banner1, setBanner1]=useState([]);
  const [banner2, setBanner2]=useState([]);
  const [banner3, setBanner3]=useState([]);
  const [banner4, setBanner4]=useState([]);
  const [noutati, setNoutati]=useState([]);
  const [noutatiTitle, setNoutatiTitle]=useState([]);
  const [bestsale, setBestsale]=useState([]);
  const [bestsaleTitle, setBestsaleTitle]=useState([]);
  
  //let slides=[];


  useEffect(() => {
    console.log(window);
    if(window.tara){
        window.tara=window.tara;
    }else{
        window.tara='ro';
    }

    if(window.lang_code){
        window.lang_code=window.lang_code;
    }else{
        window.lang_code='ro';
    }
 

    
    


    let url='index.php?dispatch=react.home&tr='+window.tara+'&lang_code='+window.lang_code;
   console.log(url);

   

    const fetchProdus = async () => {
            
            await fetch(url) 
            .then(res => res.json())
            .then(
            (result) => {
                
                //console.log(result);
                setMainBanner(result?.main_banner);
                setBanner1(result?.banner1);
                setBanner2(result?.banner2);
                setBanner3(result?.banner3);
                setBanner4(result?.banner4);
                setNoutati(result?.noutati);
                setNoutatiTitle(result?.noutati_title);
                setBestsale(result?.bestsale);
                setBestsaleTitle(result?.bestsale_title);
            
                setIsLoaded(true);
            
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                //console.log(error)
            }
            
            )
            
    }
    fetchProdus();
     
  }, [props]);
 
 
  ////console.log(imagini);
  return (
    <>
    {isLoaded===false ?
      <div>
        loading
      </div>
      :
      <>
        
      {mainBanner?.url && (  
        <div className="span16 homepage_banners">
            <div className="row-fluid">
                <div className="span8">
                    <div className="homepage-banners">
                        <div id="banner_original_103" className="ty-banner__image-wrapper">
                            <a href={mainBanner.url}>
                                <img className="ty-pict cm-image" id="det_img_585965030" src={mainBanner?.main_pair?.icon?.image_path} alt="" title="" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="span4 hidden-phone hidden-tablet">
                    <div id="banner_original_409" className="ty-banner__image-wrapper">
                        <a href={banner1.url}>
                            <img className="ty-pict cm-image" id="det_img_1203542787" src={banner1?.main_pair?.icon?.image_path} alt="pantofi cu toc" title="pantofi cu toc" />
                        </a>
                    </div>
                    <div id="banner_original_411" className="ty-banner__image-wrapper">
                        <a href={banner2.url}>
                            <img className="ty-pict cm-image" id="det_img_438397579" src={banner2?.main_pair?.icon?.image_path} alt="mocasini dama" title="mocasini dama" />
                        </a>
                    </div>
                </div>

                <div className="span4 hidden-phone hidden-tablet">
                    <div id="banner_original_410" className="ty-banner__image-wrapper">
                        <a href={banner3.url}>
                            <img className="ty-pict cm-image" id="det_img_1626072289" src={banner3?.main_pair?.icon?.image_path} alt="botine dama" title="botine dama" />
                        </a>
                    </div>
                    <div id="banner_original_412" className="ty-banner__image-wrapper">
                        <a href={banner4.url}>
                            <img className="ty-pict cm-image" id="det_img_972641615" src={banner4?.main_pair?.icon?.image_path} alt="sneakers dama" title="sneakers dama" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
      )}
        <SliderProduse items={noutati} name="noutati" title={noutatiTitle}/>
        <SliderProduse items={bestsale}  name="bestsale" title={bestsaleTitle}/>
        
    </> 
            }
          </> 
  );
};

export default Homepage;