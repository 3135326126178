import React from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import { Router, Route, IndexRoute, browserHistory, Link } from 'react-router';
import './index.css';
import App from './App';

import Splitlink from './Splitlink';
import MyComponent from './Lista';
import Lista from './Lista2';
import Product from './Produs';
import Homepage from './Homepage';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

render(
  <React.StrictMode>
      <Router
      history={browserHistory}>
          <Route
        path="/ua/"
        component={Splitlink} pagina="index"></Route>
           <Route
        path="/de/"
        component={Splitlink} pagina="index"></Route>
           <Route
        path="/es/"
        component={Splitlink} pagina="index"></Route>
        <Route
        path="/"
        component={Splitlink} pagina="index"></Route>
          <Route
        path="/*/:url.html"
        component={Splitlink} pagina="product"></Route>

          <Route
        path="/*"
        component={App} >

        <IndexRoute
          component={Splitlink} pagina="categorie"/>
          
          
        
        </Route>
      
    </Router>
    </React.StrictMode>
   ,
  document.getElementById('root')
);
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
