import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Link} from 'react-router';
import Product from './Produs';


class Lista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

 

  componentDidMount() {
    var cat=0;
   if(window.category_id){
    cat=window.category_id;
   }else{
    cat=55;
   }
   console.log( cat);
    fetch('https://dev3.attshop.ro/list_prods.php?category_id='+cat) 
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({
            isLoaded: true,
            items: result.items
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }


  render() {
    const { error, isLoaded, items } = this.state;
    
      return (
        <div>
          {items.map(item => (
            <div class="ty-column5 swiper-slide">
            <Link to={`/product/${item.product_id}`}>
            <div class="ty-grid-list__item ty-quick-view-button__wrapper a{item.product_id} ty-grid-list__item--overlay">
                  <div class="ty-grid-list__image" data-id={item.product_id}>
                  <LazyLoadImage  src={item.image_link} />
                    <div class="ty-product-labels ty-product-labels--top-right   cm-reload-{item.product_id}" id="product_labels_update_{item.product_id}">
                        <div class="ty-product-labels__item   ty-product-labels__item--discount">
                          <div class="ty-product-labels__content"> 19%</div>
                        </div>
                      
                    </div>
                  </div>
                  <div class="ty-grid-list__item-name"><bdi>
                      {item.product}
                    </bdi>
                  </div>
                  <div class="grid-list__rating">    
                  </div>
                  <div class="ty-grid-list__price ">
                    <span class="cm-reload-{item.product_id}" id="old_price_update_{item.product_id}">
                        <span class="ty-list-price ty-nowrap" id="line_list_price_{item.product_id}"><span class="ty-strike"><bdi><span id="sec_list_price_{item.product_id}" class="ty-list-price ty-nowrap">147,00</span>&nbsp;<span class="ty-list-price ty-nowrap">lei</span></bdi></span></span>
                      
                    </span>
                    <span class="cm-reload-{item.product_id} ty-price-update" id="price_update_{item.product_id}">
                        <input type="hidden" name="appearance[show_price_values]" value="1"/>
                        <input type="hidden" name="appearance[show_price]" value="1"/>
                        <span class="ty-price" id="line_discounted_price_{item.product_id}"><bdi><span id="sec_discounted_price_{item.product_id}" class="ty-price-num">119,00</span>&nbsp;<span class="ty-price-num">lei</span></bdi></span>
                      
                    </span>
                  </div>
                  {item.culori}
            </div>
            </Link>
          </div>
          ))}
          
        </div>
       
      );
    
  }
}

export default Lista;