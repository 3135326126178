import React from 'react'
import ContentLoader from 'react-content-loader'

const ResponsiveProduct = props => (
  <ContentLoader
    speed={2}
    width={1440}
    height={1600}
    viewBox="0 0 1440 1600"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="10" y="24" rx="0" ry="0" width="440" height="540" />
    <rect x="460" y="24" rx="0" ry="0" width="440" height="540" />

    <rect x="10" y="580" rx="0" ry="0" width="440" height="540" />
    <rect x="460" y="580" rx="0" ry="0" width="440" height="540" />
  
    <rect x="930" y="24" rx="0" ry="0" width="440" height="26" />


    <rect x="930" y="80" rx="20" ry="20" width="146" height="32" />
    <rect x="1090" y="80" rx="20" ry="20" width="66" height="32" />


    <rect x="930" y="290" rx="20" ry="0" width="60" height="50" />
    <rect x="1000" y="290" rx="20" ry="0" width="60" height="50" />
    <rect x="1070" y="290" rx="20" ry="0" width="60" height="50" />
    <rect x="1140" y="290" rx="20" ry="0" width="60" height="50" />
    <rect x="1210" y="290" rx="20" ry="0" width="60" height="50" />

    <rect x="930" y="360" rx="20" ry="0" width="350" height="60" />
    <rect x="1300" y="360" rx="20" ry="0" width="60" height="60" />
      
    <circle cx="970" cy="190" r="40"  width="60" height="60" />
    <circle cx="1060" cy="190" r="40"  width="60" height="60" />
    <circle cx="1150" cy="190" r="40"  width="60" height="60" />
    <circle cx="1240" cy="190" r="40"  width="60" height="60" />
   

   

  </ContentLoader>
)



export default ResponsiveProduct