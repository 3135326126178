import React, {useState, useEffect} from 'react';
import $ from 'jquery'; 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Link} from 'react-router';
import CheckBox from "./checkbox-culori";
import { useSearchParams } from 'react-router-dom';




const CuloriFilter = ({filter, items}) => {
	
	
	 const [filter_culori, setCulori] = useState([]);
	 const [filter_toc, setToc] = useState([]);
	 const [filter_tiptoc, setTipToc] = useState([]);
	 const [filter_brands, setBrands] = useState([]);
     const [filter_marimi, setMarimi] = useState([]);
     const [culori, setCulori1] = useState(items?.variants);
     const [titlu, setTtilu] = useState(items?.filter);

	 useEffect(() => {

			
		setCulori1(items?.variants);
			let filtre1=window.location.search;
			let filtre2=filtre1.split('?features_hash=');
			if(filtre2.length>1){
				let filtre=filtre2[1].split('_');

				let filter_culori1=[];
				let filter_tiptoc1=[];
				let filter_toc1=[];
				let filter_brands1=[];
				let filter_marimi1=[];
				filtre.forEach(item=>{
					let fl=item.split('-');
						fl.forEach(item2=>{
							if(fl[0]==2 && item2!=2){
								filter_culori1.push(item2);
							}
							if(fl[0]==4 && item2!=4){
								filter_brands1.push(item2);
							}
							if(fl[0]==6 && item2!=6){
								filter_tiptoc1.push(item2);
							}
							if(fl[0]==7 && item2!=7){
								filter_toc1.push(item2);
							}
							if(fl[0]==1 && item2!=1){
								filter_marimi1.push(item2);
							}
							//console.log(item2);
						})
				})
        //console.log(filter_culori1);
				setCulori(filter_culori1);
				setTipToc(filter_tiptoc1);
				setToc(filter_toc1);
				setBrands(filter_brands1);
				setMarimi(filter_marimi1);
			}

		}, [items]);
	
	
	
	const handleItemClick = (checked, brand) =>{

		function removeA(arr) {
			var what, a = arguments, L = a.length, ax;
			while (L > 1 && arr.length) {
				what = a[--L];
				while ((ax= arr.indexOf(what)) !== -1) {
					arr.splice(ax, 1);
				}
			}
			return arr;
		}
		

		console.log(checked);
		console.log(brand);
		let fl_culori=filter_culori;
		if(checked=='checked'){
			fl_culori.push(brand);
		}else{
      console.log('rem');
			removeA(fl_culori, brand.toString());
			
			
		}
		setCulori(fl_culori);
		
		//console.log(filter_culori);
		let querystring='?features_hash=';
		let querystring1='features_hash=';
		let fltz1=[];
		
    	if(filter_culori.length>0){
			fltz1.push('2-'+filter_culori.join('-'));
		}
		if(filter_tiptoc.length>0){
			fltz1.push('6-'+filter_tiptoc.join('-'));
		}
		if(filter_toc.length>0){
			fltz1.push('7-'+filter_toc.join('-'));
		}
		if(filter_brands.length>0){
			fltz1.push('4-'+filter_brands.join('-'));
		}
		if(filter_marimi.length>0){
			fltz1.push('1-'+filter_marimi.join('-'));
		
		}
		
		
			querystring=querystring+fltz1.join('_');
			querystring1=querystring1+fltz1.join('_');
		

		let url=window.location.origin + window.location.pathname + querystring;
		console.log(url);
		window.history.pushState(1, 'Title', url);
		filter(querystring1);
	}
	const doHideFilters = (tip) =>{
		if(window.innerWidth<768){
			$('.cm-product-filters .'+tip).removeClass(tip+'-active');
		}
	}

	
	
	return (
		<div className="block border-b border-gray-300 pb-7 mb-7">
			<div className="sculori filtt">
				<div className="menu-mask1" onClick={() => doHideFilters('sculori')}></div>
				<div className="plp-sheets">
        <h4>{items.filter}</h4>
					<div className="ty-product-filters__block">
						<ul className="ty-product-filters ">
											
								
								{Object.keys(culori).map( (item, i) => ( 
									<>
									{culori[item].disabled==true ?
									<></>
									:
									<li className="ty-product-filters__color-list-item cm-product-filters-checkbox-container">
								
									<CheckBox
										key={culori[item].variant_id}
										label={culori[item].variant}
										color={culori[item].color}
										name={culori[item].variant.toLowerCase()}
										value={culori[item].variant_id}
										filter={handleItemClick}
										checked={culori[item]?.selected ? 'selected':''}
									/>
									</li>
									}
									</>
								))}
							</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CuloriFilter;