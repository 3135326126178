import React, { Component, useEffect, useState } from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {useHistory, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Scrollbar } from 'swiper/modules';
import Parser from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import Product from '../Produs';
import $ from 'jquery';
import axios from 'axios'; 

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';



const Subcategorii = ({subcategorii, doLinkChange})  => {
  const [items, setItems]=useState([]);
  const [isLoaded, setIsLoaded]=useState(true);
  const [crurl, setCrurl]=useState();
  
  
  
  //let slides=[];
  
  function sortData(key, data, type) {
    let ordered = [];
    let compareFunction = function(a, b) {
      return parseInt(data[b][key]) - parseInt(data[a][key]);
    };
    if (type === "asc") {
      compareFunction = function(a, b) {
         return parseInt(data[a][key]) - parseInt(data[b][key]);
      }
    }
    Object.keys(data).sort(compareFunction).forEach(function(key) {
    
      ordered.push(data[key]);
    });
    return ordered;
  } 
  
  useEffect(() => {
    console.log(subcategorii);
   
    subcategorii=sortData("position", subcategorii, 'asc');
    setItems(subcategorii)

    //console.log(subcategorii);
     
  }, [subcategorii]);


  
  const doLinkChange1 = async (event,catnm) =>{
    event.preventDefault();
    console.log(catnm);
    console.log(window.location);
      let linkz=window.location.origin+'/'+catnm;
      let linkzs=window.location.href;
      let dtt=linkz.split(window.location.origin+'/');
          if(window.comp_id == 28){
             linkz=window.location.origin+'/ua/'+catnm;
             linkzs=window.location.href;
             dtt=linkz.split(window.location.origin+'/ua/');
            
          }
          if(window.comp_id == 29){
            linkz=window.location.origin+'/de/'+catnm;
            linkzs=window.location.href;
            dtt=linkz.split(window.location.origin+'/de/');
           
         }
         if(window.comp_id == 23){
          linkz=window.location.origin+'/es/'+catnm;
          linkzs=window.location.href;
          dtt=linkz.split(window.location.origin+'/es/');
         
       }
          console.log(linkz);

          window.history.pushState(catnm, 'Title', linkz);


       doLinkChange(catnm);
  }


 
 
  //console.log(imagini);
  return (
    <>
    {isLoaded===false ?
      <div>
        loading
      </div>
      :
      <>
        <div className="subcats">
          <div className="ty-wysiwyg-content" data-ca-live-editor-object-id="0" data-ca-live-editor-object-type="">
              <ul className="subcategories clearfix">
            
                {Object.keys(items).map( (item, i) => ( 
                  <li className="ty-subcategories__item">
                      <a className="subcat_select" href={`/${items[item]?.seo_name}/`} onClick={e => doLinkChange1(e,items[item]?.seo_name)}>
                          <div className="brd">
                              <span>
                                  <span className={`img ${items[item]?.category_ro.replaceAll(' ','-').toLowerCase()} `}></span>
                              </span>
                          </div>
                          <span>{items[item]?.main_pair?.detailed?.alt}</span>
                      </a>
                  </li>
                ))}

              </ul>
          </div>
      </div>

      </> 
    }
  </> 
  );
};

export default Subcategorii;