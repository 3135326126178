import React, {useState, useEffect} from 'react';
import $ from 'jquery'; 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Link} from 'react-router';
import CheckBox from "./checkbox";
import { useSearchParams } from 'react-router-dom';




const TocFilter = ({filter, items}) => {
	
	
	 const [filter_toc, setToc] = useState([]);
	 const [filter_tiptoc, setTipToc] = useState([]);
	 const [filter_brands, setBrands] = useState([]);
     const [filter_marimi, setMarimi] = useState([]);
	 const [filter_culori, setCulori] = useState([]);
     const [toc, setToc1] = useState(items?.variants);
     const [titlu, setTtilu] = useState(items?.filter);
	
	 useEffect(() => {

			
		setToc1(items?.variants);
			let filtre1=window.location.search;
			let filtre2=filtre1.split('?features_hash=');
			if(filtre2.length>1){
				let filtre=filtre2[1].split('_');

				let filter_toc1=[];
				let filter_tiptoc1=[];
				let filter_brands1=[];
				let filter_marimi1=[];
				let filter_culori1=[];
				filtre.forEach(item=>{
					let fl=item.split('-');
						fl.forEach(item2=>{
							if(fl[0]==2 && item2!=2){
								filter_culori1.push(item2);
							}
							if(fl[0]==4 && item2!=4){
								filter_brands1.push(item2);
							}
							if(fl[0]==6 && item2!=6){
								filter_tiptoc1.push(item2);
							}
							if(fl[0]==7 && item2!=7){
								filter_toc1.push(item2);
							}
							if(fl[0]==1 && item2!=1){
								filter_marimi1.push(item2);
							}
							//console.log(item2);
						})
				})
				setCulori(filter_culori1);
				setBrands(filter_brands1);
				setTipToc(filter_tiptoc1);
				setToc(filter_toc1);
				setMarimi(filter_marimi1);
			}

		}, [items]);
	//console.log(filter_brands);
	//console.log(filter_marimi);
	//console.log(filtre);
	
	
	const handleItemClick = (checked, toc) =>{

		function removeA(arr) {
			var what, a = arguments, L = a.length, ax;
			while (L > 1 && arr.length) {
				what = a[--L];
				while ((ax= arr.indexOf(what)) !== -1) {
					arr.splice(ax, 1);
				}
			}
			return arr;
		}
		

		console.log(checked);
		console.log(toc);
		let fl_toc=filter_toc;
		if(checked=='checked'){
			fl_toc.push(toc);
		}else{
			removeA(fl_toc, toc.toString());
			
			
		}
		setTipToc(fl_toc);
		
		//console.log(filter_tiptocs);
		let querystring='?features_hash=';
		let querystring1='features_hash=';
		let fltz1=[];

		if(filter_culori.length>0){
			fltz1.push('2-'+filter_culori.join('-'));
		}
		if(filter_tiptoc.length>0){
			fltz1.push('6-'+filter_tiptoc.join('-'));
		}
		if(filter_toc.length>0){
			fltz1.push('7-'+filter_toc.join('-'));
		}
		if(filter_brands.length>0){
			fltz1.push('4-'+filter_brands.join('-'));
		}
		if(filter_marimi.length>0){
			fltz1.push('1-'+filter_marimi.join('-'));
		
		}
		
		
			querystring=querystring+fltz1.join('_');
			querystring1=querystring1+fltz1.join('_');
		

		let url=window.location.origin + window.location.pathname + querystring;
		console.log(url);
		window.history.pushState(1, 'Title', url);
		filter(querystring1);
	}

	const doHideFilters = (tip) =>{
		if(window.innerWidth<768){
			$('.cm-product-filters .'+tip).removeClass(tip+'-active');
		}
	}

	
	
	return (
		<div className="block border-b border-gray-300 pb-7 mb-7">
			<div className="sstoc2 filtt">
				<div className="menu-mask1" onClick={() => doHideFilters('sstoc2')}></div>
				<div className="plp-sheets">
					<h4>{items.filter}</h4>
					<div className="ty-product-filters__block">
						<ul className="ty-product-filters ">
											
								
								{Object.keys(toc).map( (item, i) => ( 
									<>
									{toc[item].disabled==true ?
									<></>
									:
									<li className="cm-product-filters-checkbox-container ty-product-filters__group">
								
									<CheckBox
										key={toc[item].variant_id}
										label={toc[item].variant}
										name={toc[item].variant.toLowerCase()}
										value={toc[item].variant_id}
										filter={handleItemClick}
										checked={toc[item]?.selected ? 'selected':''}
									/>
									</li>
									}
									</>
								))}
							</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TocFilter;