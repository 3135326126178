import React, { Component, useEffect, useState } from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import {useHistory, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Parser from 'html-react-parser';
import $ from 'jquery';
import axios from 'axios'; 
import Notification from './Notification';
import { NumericFormat } from 'react-number-format';
import ResponsiveProduct from "./SkeletonProduct";
import ResponsiveProductMobile from "./SkeletonProductMobile";
import SliderProduse from './components/sliderProduse';
import 'swiper/css';
import 'swiper/css/pagination';

import './Product.css';


const Product = props => {
  const [imagini1, setImagini]=useState([props.imfirst]);
  const [produs, setProdus]=useState([]);
  const [promo, setPromo]=useState([]);
  const [promoo, setPromoo]=useState([]);
  const [moneda, setMoneda]=useState(props.moneda);
  const [slides, setSlides]=useState([]);
  const [selectedP, setSelectedP]=useState(0);
  const [selectedV, setSelectedV]=useState();
  const [features, setFeatures]=useState([]);
  const [prodFeatures, setProdFeatures] = useState([]);
  const [traduceri, setTraduceri] = useState([]);
  const [selHtml, setSelHtml]=useState();
  const [marimi, setMarimi]=useState([]);
  const [colectie, setColectie]=useState([]);
  const [isLoaded, setIsLoaded]=useState(props.loadp);
  
  //let slides=[];



  const selectMarime = (product_id) =>{
    //console.log(product_id);
    setSelectedP(product_id);
    $('#alegemar').hide();
    $('.alegemar').hide();
    if(features[15]){
    var ddd=$('.optlb_'+product_id).text().trim();
    var fff = ddd.indexOf("-");
    
    var myArray = ddd.split("-");
   
    var mar_mare_a='';
    var mar_mare_a2='';
    if(myArray.length>1){
        var mar_mare1=parseInt(myArray[0])-2;
        var mar_mare2=parseInt(myArray[1])-2;
        mar_mare_a=mar_mare1+'-'+mar_mare2;
    }else{
        var mar_mare1=parseInt(myArray[0])-1;
        mar_mare_a=mar_mare1;
        var mar_mare12=parseInt(myArray[0])-2;
        mar_mare_a2=mar_mare12;
    }
    var mar_mica_a='';
    var mar_mica_a2='';
    if(myArray.length>1){
        var mar_mica1=parseInt(myArray[0])+2;
        var mar_mica2=parseInt(myArray[1])+2;
        mar_mica_a=mar_mica1+'-'+mar_mica2;
    }else{
        var mar_mica1=parseInt(myArray[0])+1;
        mar_mica_a=mar_mica1;
        var mar_mica12=parseInt(myArray[0])+2;
        mar_mica_a2=mar_mica12;
    }
    console.log(mar_mare_a);
    console.log(mar_mica_a);
    var mar_act=$('.optlb_'+product_id).text().replace('-', '/');
    var mar_mare=mar_mare_a;
    var mar_mica=mar_mica_a;
    var infotext=features[15]['variant']; 
    var infotext2=features[15]['suffix']; 
    var variant_id=features[15]['variant_id']; 
        if(variant_id==420){
            if(mar_mare1>1){
                console.log();
                $('span.red_info.ss span').show();
                $('span.rfd.ss').show();
              // $('span.red_info.ss span').html(infotext.replaceAll('-','<b>').replaceAll('+','</b>').replaceAll('|','<br/>')+' <b style="color:red">'+mar_mare+'</b>');
                $('span.rfd.ss span').html(infotext2.replaceAll('#','<b>'+mar_act+'</b>').replaceAll('-','<b>').replaceAll('+','</b>').replaceAll('|','<br/>')+' <b style="color:red">'+mar_mica+'</b>');
            }
        }
        if(variant_id==419){
            if(mar_mica1>1){
                $('span.rfd.ss').show();
                $('span.red_info.ss span').show();
              // $('span.red_info.ss span').html(infotext.replaceAll('-','<b>').replaceAll('+','</b>').replaceAll('|','<br/>')+':  <b style="color:red">'+mar_mica+'</b>');
                $('span.rfd.ss span').html(infotext2.replaceAll('#','<b>'+mar_act+'</b>').replaceAll('-','<b>').replaceAll('+','</b>').replaceAll('|','<br/>')+'  <b style="color:red">'+mar_mare+'</b>');
            }
        }
        if(variant_id==537){
            if(mar_mare12>1){
                console.log();
                $('span.red_info.ss span').show();
                $('span.rfd.ss').show();
              // $('span.red_info.ss span').html(infotext.replaceAll('-','<b>').replaceAll('+','</b>').replaceAll('|','<br/>')+' <b style="color:red">'+mar_mare+'</b>');
                $('span.rfd.ss span').html(infotext2.replaceAll('#','<b>'+mar_act+'</b>').replaceAll('-','<b>').replaceAll('+','</b>').replaceAll('|','<br/>')+' <b style="color:red">'+mar_mare_a2+'</b>');
            }
        }
        if(variant_id==538){
            if(mar_mica12>1){
                $('span.rfd.ss').show();
                $('span.red_info.ss span').show();
              // $('span.red_info.ss span').html(infotext.replaceAll('-','<b>').replaceAll('+','</b>').replaceAll('|','<br/>')+':  <b style="color:red">'+mar_mica+'</b>');
                $('span.rfd.ss span').html(infotext2.replaceAll('#','<b>'+mar_act+'</b>').replaceAll('-','<b>').replaceAll('+','</b>').replaceAll('|','<br/>')+'  <b style="color:red">'+mar_mica_a2+'</b>');
            }
        }
      }
  }

  const makeid = (length) =>  {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

  const doCupon = (event,product_id, cupon, cupon_aplicat) =>{
    event.preventDefault();
    console.log(product_id);
    console.log(cupon);
    let rnd=makeid(5);
    axios({
      method: 'post',
      url: 'index.php?'+rnd,
      data: { coupon_code: cupon, dispatch: 'checkout.apply_coupon', full_render: 'Y',is_ajax:1  },
      headers: { "Content-Type": "multipart/form-data" },
    }).then(function (response) {
        console.log(response);
        $('#cp'+product_id).html(cupon_aplicat); 
        $('#pending_coupon').val(cupon);
        setSelectedV(cupon);
        $('.aplica_cup').addClass('app');
    });
    
  }

  

  const doColectie = async (e,product_id, url) =>{
    e.preventDefault();
    let url2 = "";
    console.log(url);
    setSelectedP(0);
    
    $("#alegemar").show();
    url2 = "/products.php?r=da";
    if(window.comp_id == 28 || window.comp_id == 29 || window.comp_id > 22 || window.comp_id == 6 || window.comp_id == 10  || window.comp_id == 21  || window.comp_id == 22  || window.comp_id == 20  || window.comp_id == 19){
      url2='index.php?dispatch=react.product&comp_id='+window.comp_id;
    }
    await fetch(url2+"&product_id=" + product_id+"&lang_code="+window.lang)
                      .then(res => res.json())
                      .then(
                        (result) => {
                          
                          console.log(result);
                          
                         
                          let slides2=[];
                          setImagini([]);
                          setSlides([]);
                          setProdus(result);
                          setImagini(result.poze);
                          setMarimi(result.marimi);
                          setColectie(result.colectie);
                          setFeatures(result.features);
                          setProdFeatures(result.product_features);
                          setTraduceri(result.traduceri);
                          setPromo(result.promotie)
                          setPromoo(result.promo)
                          let imagini=result.poze;
                          if(imagini){
                        
                            //console.log(imagini);
                              imagini.forEach((poza) => {  //console.log(poza);
                                slides2.push(
                                  <SwiperSlide key={poza.pair_id}>
                                    <img src={poza.poza} alt=""/>
                                  </SwiperSlide>
                                )
                                });
                            }
                            setSlides(slides2);
                            setIsLoaded(true);
                            setTimeout(() => {
                              console.log('da');
                              $(".ty-product-options__radio:checked").removeAttr('checked'); 
                              $(".ty-product-options__radio").removeAttr('checked'); 
                              $('.ty-product-options__radio').prop('checked', false);
                            }, 300);
                            
                            if(window.innerWidth>768){
                            window.history.pushState(product_id, "Title", url);
                            }
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                          console.log(error)
                        }
                      )
  }

  const doSubmit = (e,product_id, price, product_code) =>{
    e.preventDefault();
    //console.log(product_id);
    setSelectedP(product_id);
    var form = $('.adaugaincos');
    //console.log(form);
    var actionUrl = form.attr('action');

    var bodyFormData = new FormData();
    bodyFormData.append('result_ids', 'cart_status*,wish_list*,checkout*,account_info*');
    bodyFormData.append('redirect_url', 'index.php?dispatch=products.view&product_id='+product_id);
    bodyFormData.append('product_data['+product_id+'][product_id]', product_id);
    bodyFormData.append('product_data['+product_id+'][price]', price);
    bodyFormData.append('product_data['+product_id+'][product_code]', product_code);
    bodyFormData.append('product_data['+product_id+'][amount]', 1);
    bodyFormData.append('zpending_coupon', selectedV);
    bodyFormData.append('dispatch[checkout.add..'+product_id+']','');
    bodyFormData.append('full_render','Y');
    bodyFormData.append('is_ajax', '1');

    let urls='/';
    if(window.comp_id == 28){
      urls='/ua/';
    }
    if(window.comp_id == 29){
      urls='/de/';
    }
    if(window.comp_id == 23){
      urls='/es/';
    }
   // $.ceAjax('submitForm', form, $('#button_cart_'+product_id));
   axios({
        method: 'post',
        url: urls,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(function (response) {
        //handle success
        ////console.log(response);
        let htmlss=response.data.html;
        if(htmlss){
          ////console.log(htmlss);
            Object.keys(htmlss).map(function(key) {
              let htm=htmlss[key];
              
              let position = key.search("art_status");
              if(position>0){
                var ddxxxx=document.querySelectorAll('[id*="cart_status"]'); 
                 $(ddxxxx).html(htm);
              }
              let position2 = key.search("ccount_info");
              if(position2>0){
                var ddxxxx=document.querySelectorAll('[id*="account_info"]'); 
                 $(ddxxxx).html(htm);
              }
              let position3 = key.search("heckout_info_summary");
              if(position3>0){
                var ddxxxx=document.querySelectorAll('[id*="checkout_info_summary"]'); 
                 $(ddxxxx).html(htm);
              }
              
            })
        }
        let addcart=response.data.notifications;
        if(addcart){
   
          
            //console.log(addcart);
            Object.keys(addcart).map(function(key) {
              let notf=addcart[key];
              //console.log(notf.message);
              if(notf.type=='I'){
                  //setSelHtml(notf.message);

                
                  $('body').append(
                      '<div class="ui-widget-overlay" style="z-index:1010" data-ca-notification-key="' + key + '"></div>'
                  );

                  var notification = $('<div class="cm-notification-content cm-notification-content-extended notification-content-extended ' + (notf.message_state == "I" ? ' cm-auto-hide' : '') + '" data-ca-notification-key="' + key + '">' +
                      '<h1>' + notf.title + '<span class="cm-notification-close close"></span></h1>' +
                      '<div class="notification-body-extended">' +
                      notf.message +
                      '</div>' +
                      '</div>');

                  var notificationMaxHeight = window.innerHeight - 300;

                  $(notification).find('.cm-notification-max-height').css({
                      'max-height': notificationMaxHeight
                  });

                  // FIXME I-type notifications are embedded directly into the body and not into a container, because a container has low z-index and get overlapped by modal dialogs.
                  //container.append(notification);
                  $('body').append(notification);
                  notification.css('top', window.innerHeight / 2 - (notification.height() / 2));
            }

            })
            
          }
       
        
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    
  }
 

  useEffect(() => {
    setIsLoaded(false);
    
    $('.show-marimi').click(function(){
      $('.card-block').hide();
      $('.ty-icon-down-open').removeClass('down');
      $('.show-marimi .ty-icon-down-open').addClass('down');
      $('#details-marimi').show();
      
      
  });
  $('.show-delivery').click(function(){
      $('.card-block').hide();
      $('#details-marimi').show();
      $('.ty-icon-down-open').removeClass('down');
      $('.show-delivery .ty-icon-down-open').addClass('down');
      $('#details-delivery').show();
    
  });
  $('.show-schimb').click(function(){
      $('.card-block').hide();
      $('#details-marimi').show();
      $('.ty-icon-down-open').removeClass('down');
      $('.show-schimb .ty-icon-down-open').addClass('down');
      $('#details-return').show();
      
  });
  $('.type-sizing-content2').click(function(){
        $('.mht').animate({
          scrollTop: 1000
      }, 'slow');
    });

   
    //console.log(props.product.colectie);
    let slides2=[];
    setImagini([]);
    setSlides([]);
    setProdus(props.product);
    setMoneda(props.moneda);
    setPromo(props.product.promotie)
    setPromoo(props.product.promo)
    setImagini(props.product.poze);
    setMarimi(props.product.marimi);
    setColectie(props.product.colectie);
    setFeatures(props.product.features);
    setProdFeatures(props.product.product_features);
    setTraduceri(props.product.traduceri);
    let imagini=props.product.poze;
    if(imagini){
   
      //console.log(imagini);
        imagini.forEach((poza) => {  //console.log(poza);
          slides2.push(
            <SwiperSlide key={poza.pair_id}>
              <img src={poza.poza} alt=""/>
            </SwiperSlide>
          )
          });
      }
      setSlides(slides2);
      setIsLoaded(true);
      
  }, [props]);
 
 
  //console.log(imagini);
  return (
    <>
     {isLoaded === false ? (
        <div>
          {window.innerWidth < 768 ? (
            <div
              style={{ width: "102%", marginTop: "-48px", marginLeft: "-20px" }}
            >
              <ResponsiveProductMobile style={{ width: "120%" }} width1={420} />
            </div>
          ) : (
            <ResponsiveProduct style={{ width: "100%" }} />
          )}
        </div>
      ) : (
        
      <>
    {produs.price >0  && (
     <div className='spp pop'>
     
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
        slidesPerView="1.3"
        rebuildOnUpdate="true"
      >
       {slides}
        
      </Swiper>
        <div className="ty-product-block__left">
        <form action="" method="post" name={`product_form_${selectedP}`} enctype="multipart/form-data" className="cm-disable-empty-files  cm-ajax cm-ajax cm-ajax-full-render cm-ajax-status-middle  cm-processed-form adaugaincos">
            <input type="hidden" name="result_ids" value="cart_status*,wish_list*,checkout*,account_info*" />
            <input type="hidden" name="redirect_url" value={`index.php?dispatch=products.view&amp;product_id=${selectedP}`} />
            <input type="hidden" name={`product_data[${selectedP}][product_id]`} value={selectedP} />
            <input type="hidden" name="appearance[show_price_values]" value="1" />
            <input type="hidden" name="appearance[show_price]" value="1" />
            <input type="hidden" name={`product_data[${selectedP}][price]`} value={props.product.price} />
            <input type="hidden" name={`product_data[${selectedP}][product_code]`} value={props.product.product_code} />
            <input type="hidden" name={`product_data[${selectedP}][amount]`} value={1} />
            <input type="hidden" name="appearance[show_product_options]" value="1" />
            <input type="hidden" name="appearance[details_page]" value="1" />
            <input type="hidden" name="appearance[info_type]" value="D" />
            <input type="hidden" name={`dispatch[checkout.add..${selectedP}]`} />
            <input type="hidden" name="full_render" value="Y" />
            <input type="hidden" name="is_ajax" value="1" />
            <input type="hidden" name="zpending_coupon" id="pending_coupon" value={selectedV}></input>

            <h1 className="ty-product-block-title"><bdi> {produs.product}</bdi></h1>

            <div className="brand"></div>

            <div className="prices-container price-wrap">
                <div className="ty-product-prices">
                    <div className="ty-product-block__price-actual">
                        <span className="cm-reload-70134 ty-price-update" id={`price_update_${selectedP}`}>
                            
                     

                            <span className={`ty-price ${produs?.list_price >0 ? 'red' : ''}`} id="line_discounted_price_70134">
                                <bdi>
                                    <span id="sec_discounted_price_70134" className="ty-price-num">
                                    <NumericFormat
                                    value={produs?.price?.split(".")[0]}
                                   
                                    thousandSeparator=" "
                                    displayType="text"
                                  />
                                  
                                  <sup>{produs?.price?.split(".")[1]}</sup>
                                </span>
                                &nbsp;<span className="ty-price-num">{moneda}</span>
                                </bdi>
                            </span>
                        </span>
                    </div>
                    {produs?.list_price >0 &&(
                      <>
                      <span className="cm-reload-70134" id="old_price_update_70134">
                          <span className="red ty-list-price ty-nowrap" id="line_list_price_70134">
                              <span className="list-price-label">Pret vechi:</span>
                              <span className="ty-strike">
                                  <bdi>
                                      <span id="sec_list_price_70134" className="ty-list-price ty-nowrap">{produs?.list_price?.split('.')[0]}<sup>{produs?.list_price?.split('.')[1]}</sup></span>&nbsp;<span className="ty-list-price ty-nowrap">{moneda}</span>
                                  </bdi>
                              </span>
                          </span>
                      </span>
                    
                      <div className="tooltip1">
                              <img
                                src="/img/info_prp.png"
                                style={{
                                  width: "18px",
                                  marginLeft: "0px",
                                  marginTop: "-5px",
                                }}
                              />
                              <span className="tooltiptext">
                                {traduceri?.pret_producator}
                              </span>
                            </div>
                      </>
                     )}

                    {promo?.price > 0 && (
                    <div class="promo_discount" >
                            <span class="price">
                           
                                  {promo.price} {moneda}
                           </span>
                           {promo.voucher}  {promo.cupon}
                           <a onClick={(e)=>doCupon(e,produs.product_id, promo.cupon, promo.cupon_aplicat)} id={`cp${produs.product_id}`} className="aplica_cup">{promo.aplica}</a>
                       
                    </div>
                    )}
                     <div class="ty-product-block__sku">
                        SKU: {produs.cod_site}
                    </div>
                    <div className={`ty-product-labels ty-product-labels--top-right   cm-reload-${produs.product_id}`} id={`product_labels_update_${produs.product_id}`}>
                     {produs.discount > 0 && (
                          <div className="ty-product-labels__item   ty-product-labels__item--discount">
                            <div className="ty-product-labels__content">{Math.round(((produs.price*100/produs.list_price)-100).toString().replace("-", ""))}</div>
                          </div>
                       )}  
                      </div>
                      {Parser(promoo.toString())}
                      {Parser(produs.promotii.toString())}
                </div>
            </div>
            
            <div className="colectie">
                <div className="colectie2   swiper-container">
                 <div className="swiper-wrapper">
                 {colectie.map(col => { 
                      return (
                       
                        <div className="cotitm" style={{textAlign:'center', marginBottom: '15px', fontSize: '10px'}}>
                                  <a className={`${col.product_id==produs.product_id ? 'col_active': ''}`} data-id={col.product_id} onClick={(e)=>doColectie(e,col.product_id,col.url)} href={`/index.php?dispatch=products.view&product_id=${col.product_id}`}>
                                    <img src={col.imagine} />
                                  </a>
                                  {col.culoare}
                        </div>
                       
                      )
                    })}
                                 
                                
                                
                </div>
                 
                </div>
               
           </div>
           {produs.decalat && (
            <>
            {Parser(produs.decalat.toString())}
            </>
           )}
            <div className="ty-product-block__option">
            <label className="ty-control-group__label ty-product-options__item-label">{produs?.marime_titlu}:</label>
                    <div className="type-sizing-content2"  >
                        <svg xmlns="https://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39">
                          <g style={{fill:'none',strokeWidth:1,stroke:'none'}}><circle cx="21.5" cy="20" r="18.5" fill="#FEAC2F"></circle><g fill="#000">
                            <path d="M18.5 0C8.3 0 0 8.3 0 18.5 0 28.7 8.3 37 18.5 37 28.7 37 37 28.7 37 18.5 37 8.3 28.7 0 18.5 0ZM18.5 34.7C9.6 34.7 2.3 27.4 2.3 18.5 2.3 9.6 9.6 2.3 18.5 2.3 27.4 2.3 34.7 9.6 34.7 18.5 34.7 27.4 27.4 34.7 18.5 34.7Z"></path>
                            <polygon points="17.3 25.4 19.6 25.4 19.6 27.7 17.3 27.7"></polygon><polygon points="17.3 9.2 19.6 9.2 19.6 23.1 17.3 23.1"></polygon>
                            </g></g>
                            </svg>
                        <a title="tabel marimis">
                        {produs?.tabel_marimi_titlu}
                        </a>
                    </div>   
           

            {marimi.map(produs1 => { 
             return (
                <>
                  <input
                      type="radio"
                      name="feature_1"
                      id={`feature_1_variant_${produs1.product_id}`}
                      data-ca-product-id={produs1.product_id}
                      className={`hidden ty-product-options__radio cm-ajax cm-ajax-force ${produs1.product_id==selectedP ? 'checked' : ''}`}
                     
                  />

                  <label for={`feature_1_variant_${produs1.product_id}`} className={`ty-product-options__radio--label optlb_${produs1.product_id}`}  onClick={()=>selectMarime(produs1.product_id)}> <span className="ty-product-option-checkbox"></span>{produs1.marime}<span className="ty-product-option-checkbox"></span> </label>  
                  </>
             )
                
                
              })}
              {produs.decalatm && (
                <>
                {Parser(produs.decalatm.toString())}
                </>
              )}
            </div>
            <div className="ty-product-block__button" style={{position:'relative'}}>
            <button
                         disabled="true"
                        className="addis ty-btn__primary ty-btn__big  cm-form-dialog-closer ty-btn alegemar"
                        type="submit"
                        id="alegemar"
                        
                      >
                        {traduceri?.alegeti_marimea}
                      </button>
              <button id={`button_cart_${selectedP}`} className="ty-btn__primary ty-btn__big ty-btn__add-to-cart cm-form-dialog-closer ty-btn" onClick={(e)=>doSubmit(e,selectedP, produs.price, produs.product_code)} type="submit" name={`dispatch[checkout.add..${selectedP}]`}>{traduceri?.add_to_cart}</button>
              
              <a className="ty-btn ty-btn__text ty-add-to-wish cm-ajax cm-submit text-button" id={`button_wishlist_.${selectedP}`} href={`${selectedP > 0 ? "/index.php?dispatch=wishlist.add&product_id="+selectedP+"&is_ajax=1" : "javascript:;"}`}></a>
            </div>
            <div className="delivz" style={{float: "left",width: "100%",marginBottom: "20px",marginTop: "-10px"}}>
                        <p style={{fontWeight:"700"}}>{traduceri?.livrare_info}</p>
                        <p style={{color:"green",fontWeight:"700"}}>{traduceri?.livrare_info_data}</p>
                    </div>
        </form>

        <div className="taburi">
                          <h3 className="tab-list-title" id="features">
                          {produs?.detalii_titlu}
                          </h3>

                          <div id="content_features" className="ty-wysiwyg-content content-features">
                            {prodFeatures && (
                              <>
                          {Object.keys(prodFeatures || {}).map( (item, i) => ( 
                        
                            <div className="ty-product-feature">
                              <span className="ty-product-feature__label">{prodFeatures[item]?.description}:</span>

                              <div className="ty-product-feature__value">
                                <ul className="ty-product-feature__multiple">
                                  <li className="ty-product-feature__multiple-item">
                                    <span className="ty-compare-checkbox" title={prodFeatures[item].variants[prodFeatures[item].variant_id].variant}>
                                      <i className="ty-compare-checkbox__icon ty-icon-ok"></i>
                                    </span>
                                    <span className="ty-product-feature__prefix"></span>{prodFeatures[item].variants[prodFeatures[item].variant_id].variant}
                                    <span className="ty-product-feature__suffix">{prodFeatures[item]?.suffix}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ))}
                          </>
                            )}
                          
                        </div>        
                  </div>        
                  <div className="collapses tbmr">
                    <div className="card">
                      <div className="card-header open-sizing-content">
                        <div className="mb-0 card-title">
                          <a
                            className={
                              "link-collapse collapses__custom-link show-marimi"
                            }
                          >
                            <img
                              src="/img/ruler.png"
                              style={{
                                width: "20px",
                                marginTop: "-6px",
                                marginRight: "3px",
                              }}
                            />{" "}
                            {produs?.blocks?.tabel_marimi_titlu}
                            <span className="ty-icon-down-open"></span>
                          </a>
                        </div>
                        <div
                          id="details-marimi"
                          className="card-block marimi-card"
                        >
                          {Parser(produs?.blocks?.tabel_marimi)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="collapses">
                    <div className="card">
                      <div className="card-header open-sizing-content">
                        <div className="mb-0 card-title">
                          <a className="link-collapse collapses__custom-link show-delivery">
                            <img
                              src="img/truck.png"
                              style={{
                                width: "20px",
                                marginTop: "-6px",
                                marginRight: "3px",
                              }}
                            />{" "}
                            {produs?.blocks?.shipping_info_titlu}
                            <span className="ty-icon-down-open"></span>
                          </a>
                        </div>
                        <div
                          id="details-delivery"
                          className="card-block shipment-card"
                        >
                          {Parser(produs?.blocks?.shipping_info)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="collapses">
                    <div className="card">
                      <div className="card-header open-sizing-content">
                        <div className="mb-0 card-title">
                          <a className="link-collapse collapses__custom-link show-schimb">
                            <img
                              src="img/exchange.png"
                              style={{
                                width: "20px",
                                marginTop: "-6px",
                                marginRight: "3px",
                              }}
                            />{" "}
                             {produs?.blocks?.schimb_info_titlu}
                            <span className="ty-icon-down-open"></span>
                          </a>
                        </div>

                        <div
                          id="details-return"
                          className="card-block reclamation-card"
                        >
                          {Parser(produs?.blocks?.schimb_info)}
                        </div>
                      </div>
                    </div>
                  


              
        </div>
      </div>
      <SliderProduse items={produs?.similare} name="similare" title={produs?.similare_titlu}/>
      </div>



    )}
    </> 
            )}
          </> 
  );
};

export default Product;